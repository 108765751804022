import React from 'react'

function ArrowUp() {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 11.3 12.6">
      <path d="M11.1 5.1l-5-5-.5.5-.5-.5-5 5 1 1 3.8-3.7v10.2h1.5V2.4l3.7 3.7z" />
    </svg>
  )
}

export default ArrowUp
