import React from 'react'

import ArrowUp from '../SVG/ArrowUp'
import WindowScroller from '../WindowScroller'

const FooterArrow = () => {
  return (
    <button
      onClick={() => WindowScroller(0)}
      className="text-sm uppercase transition-opacity ease-in-out duration-200 hover:opacity-75 focus:outline-none focus:opacity-50 leading-none tracking-widest"
    >
      Top
      <div className="w-3 mx-auto pt-2">
        <ArrowUp />
      </div>
    </button>
  )
}

export default FooterArrow
