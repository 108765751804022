import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Menu from '../Menu'
import HeaderBurger from './HeaderBurger'

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobal {
          data {
            primary_menu {
              title
              link {
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header className="absolute top-0 inset-x-0 px-ogs text-sm uppercase z-50">
        <div className="wrapper h-hh grid grid-cols-12 gap-4 items-center">
          <div className="col-start-2 lg:col-start-1 col-end-12 lg:col-end-13">
            <Menu menuItems={data.prismicGlobal.data.primary_menu} />
          </div>
          <div className="col-start-12 col-end-13 lg:hidden">
            <HeaderBurger />
          </div>
        </div>
      </header>
    )}
  />
)

export default Header
