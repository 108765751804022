import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import { TransitionPortal } from 'gatsby-plugin-transition-link'

import LayoutContext from '../../providers/LayoutProvider'
import Header from '../Header'
import MobileMenu from '../MobileMenu'
import Footer from '../Footer'
import SEO from '../SEO'

const Layout = ({ children, seoTitle, seoDescription, location }) => {
  const layoutContext = useContext(LayoutContext)

  useEffect(() => {
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVhProperty()

    window.addEventListener('resize', setVhProperty)
    return () => {
      window.removeEventListener('resize', setVhProperty)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          prismicGlobal {
            data {
              site_meta_title
              site_meta_description
            }
          }
        }
      `}
      render={data => {
        const global = data.prismicGlobal.data

        return (
          <div className="sticky-footer-layout font-sans leading-normal bg-charcoal text-white text-base tracking-wide antialiased">
            <SEO
              title={seoTitle || global.site_meta_title}
              description={seoDescription || global.site_meta_description}
              location={location}
            />
            <Header />
            <div className="relative z-20">{children}</div>
            <TransitionPortal level="top">
              <CSSTransition
                in={layoutContext.mobileMenuOpen}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <MobileMenu />
              </CSSTransition>
            </TransitionPortal>
            <Footer />
          </div>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
