import React from 'react'

function Facebook() {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 19.1 19">
      <path d="M19.1 9.6c0-5.3-4.3-9.6-9.6-9.6S0 4.3 0 9.6c0 4.7 3.4 8.7 8.1 9.4v-6.7H5.6V9.6H8V7.5C7.8 5.6 9.1 4 11 3.8h.6c.7 0 1.4.1 2.1.2v2.4h-1.2c-.8-.1-1.5.4-1.6 1.2v2.1h2.6l-.4 2.8h-2.2V19c4.8-.7 8.2-4.7 8.2-9.4z" />
    </svg>
  )
}

export default Facebook
