import React, { useContext } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Logo from '../SVG/Logo'
import LayoutContext from '../../providers/LayoutProvider'

const MenuItem = ({ title, link }) => (
  <AniLink
    className="menu-item"
    activeClassName="menu-item--active"
    partiallyActive
    fade
    to={link}
  >
    {title}
  </AniLink>
)

const Menu = ({ menuItems }) => {
  const half = menuItems.length / 2
  const first = menuItems.slice(0, half)
  const second = menuItems.slice(half)

  const layoutContext = useContext(LayoutContext)
  return (
    <nav className="flex items-center justify-center lg:space-x-9">
      {first.map((item, index) => {
        return (
          <div key={index}>
            <MenuItem title={item.title} link={item.link.url} />
          </div>
        )
      })}
      <AniLink
        onClick={() => {
          layoutContext.mobileMenuOpen &&
            layoutContext.setMobileMenuOpen(!layoutContext.mobileMenuOpen)
        }}
        className="w-64 lg:w-104 lg:px-5 hover:opacity-100 focus:opacity-100"
        fade
        to="/"
        aria-label="Beverage Brothers"
      >
        <Logo />
      </AniLink>
      {second.map((item, index) => {
        return (
          <div key={index}>
            <MenuItem title={item.title} link={item.link.url} />
          </div>
        )
      })}
    </nav>
  )
}

export default Menu
