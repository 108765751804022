import React from 'react'

function Instagram() {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 17.7 17.7">
      <circle cx="13.6" cy="4.1" r="1.1" />
      <path d="M8.9 4.3c-2.5 0-4.6 2-4.6 4.6s2 4.6 4.6 4.6 4.6-2 4.6-4.6c-.1-2.6-2.1-4.6-4.6-4.6zm0 7.5c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3c-.1 1.7-1.4 3-3 3z" />
      <path d="M12.6 0H5.1C2.3 0 0 2.3 0 5.1v7.5c0 2.8 2.3 5.1 5.1 5.1h7.5c2.8 0 5.1-2.3 5.1-5.1V5.1c0-2.8-2.3-5.1-5.1-5.1zm3.5 12.6c0 1.9-1.6 3.5-3.5 3.5H5.1c-1.9 0-3.5-1.6-3.5-3.5V5.1c0-1.9 1.6-3.5 3.5-3.5h7.5c1.9 0 3.5 1.6 3.5 3.5v7.5z" />
    </svg>
  )
}

export default Instagram
