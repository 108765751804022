import React, { useRef, useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Logo from '../SVG/Logo'
import HeaderBurger from '../Header/HeaderBurger'
import MobileMenuLink from '../MobileMenu/MobileMenuLink'
import LayoutContext from '../../providers/LayoutProvider'

const MobileMenu = () => {
  const layoutContext = useContext(LayoutContext)
  const menuRef = useRef(null)

  return (
    <StaticQuery
      query={graphql`
        query {
          prismicGlobal {
            data {
              primary_menu {
                title
                link {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => {
        const global = data.prismicGlobal.data
        return (
          <div
            ref={menuRef}
            className="fixed z-40 bg-charcoal inset-0 overflow-y-auto pb-4 lg:hidden h-screen-calc text-white antialiased"
          >
            <div className="px-ogs">
              <div className="wrapper">
                <header className="text-sm uppercase mb-10">
                  <div className="h-hh grid grid-cols-12 gap-4 items-center">
                    <div className="col-start-2 col-end-12">
                      <div className="flex items-center justify-center">
                        <MobileMenuLink
                          onClick={() => {
                            layoutContext.mobileMenuOpen &&
                              layoutContext.setMobileMenuOpen(
                                !layoutContext.mobileMenuOpen
                              )
                          }}
                          className="w-64 hover:opacity-100 focus:opacity-100 block"
                          to="/"
                          aria-label="Beverage Brothers"
                        >
                          <Logo />
                        </MobileMenuLink>
                      </div>
                    </div>
                    <div className="col-start-12 col-end-13 lg:hidden">
                      <HeaderBurger />
                    </div>
                  </div>
                </header>
                <div className="flex flex-col h-full text-center space-y-15 -mt-2px">
                  {global.primary_menu.map((item, index) => {
                    return (
                      <div key={index}>
                        <MobileMenuLink
                          to={item.link.url}
                          className="text-xl lg:text-2xl font-condensed uppercase tracking-wider leading-none"
                          onClick={() => {
                            layoutContext.setMobileMenuOpen(
                              !layoutContext.mobileMenuOpen
                            )
                          }}
                        >
                          {item.title}
                        </MobileMenuLink>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default MobileMenu
