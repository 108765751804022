import React from 'react'

import Instagram from '../SVG/Instagram'
import Facebook from '../SVG/Facebook'

const FooterSocialLinks = ({ global }) => {
  return (
    <div className="space-x-2 mb-7">
      {global.instagram_url && (
        <a
          className="w-4 inline-block"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
          href={global.instagram_url}
        >
          <Instagram />
        </a>
      )}
      {global.facebook_url && (
        <a
          className="w-4 inline-block"
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
          href={global.facebook_url}
        >
          <Facebook />
        </a>
      )}
    </div>
  )
}

export default FooterSocialLinks
