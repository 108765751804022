import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { TimelineMax } from 'gsap'

const TransitionOUT = ({ exit: { length }, node }) => {
  new TimelineMax().fromTo(
    node,
    length,
    {
      opacity: 1,
    },
    {
      opacity: 0,
    }
  )
}

const TransitionIN = ({ exit: { length }, node }) => {
  new TimelineMax().fromTo(
    node,
    length,
    {
      opacity: 0,
    },
    {
      opacity: 1,
    }
  )
}

const MobileMenuLink = props => {
  return (
    <TransitionLink
      {...props}
      exit={{
        ...props.exit,
        length: 0.5,
        zIndex: 999,
        trigger: ({ exit, node }) => TransitionOUT({ exit, node }),
      }}
      entry={{
        ...props.entry,
        delay: 0.1,
        length: 1,
        trigger: ({ exit, node }) => TransitionIN({ exit, node }),
      }}
    >
      {props.children}
    </TransitionLink>
  )
}

export default MobileMenuLink
