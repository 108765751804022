import React, { useContext } from 'react'

import LayoutContext from '../../providers/LayoutProvider'

const HeaderBurger = () => {
  const layoutContext = useContext(LayoutContext)

  return (
    <button
      onClick={() => {
        layoutContext.setMobileMenuOpen(!layoutContext.mobileMenuOpen)
      }}
      aria-label="Mobile Menu"
      type="button"
      className={`hamburger ${layoutContext.mobileMenuOpen &&
        'hamburger--menu-is-open'}`}
    >
      <div className="hamburger__inner">
        <span className="hamburger__middle-line"></span>
      </div>
    </button>
  )
}

export default HeaderBurger
