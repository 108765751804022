import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import Logomark from '../SVG/Logomark'
import FooterArrow from './FooterArrow'
import FooterSocialLinks from './FooterSocialLinks'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobal {
          data {
            email_addresses {
              email_address
            }
            facebook_url
            footer_menu {
              link {
                url
              }
              title
            }
            phone_numbers {
              phone_number
            }
            instagram_url
            address {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const global = data.prismicGlobal.data

      return (
        <footer className="relative px-ogs z-20">
          <div className="sticky-footer-layout__footer pt-15 pb-6 lg:pt-26 lg:pb-24 wrapper text-xxs lg:flex lg:space-x-gs">
            <Fade ssrReveal delay={250} duration={2250}>
              <div className="lg:w-1/4 flex justify-between items-start mb-8">
                <div className="w-19">
                  <Logomark />
                </div>
                <div className="lg:hidden">
                  <FooterArrow />
                </div>
              </div>

              <div className="lg:w-1/4 mb-3 lg:mt-3">
                <ul>
                  {global.phone_numbers
                    .filter(item => item.phone_number !== null)
                    .map((item, index) => (
                      <li key={index}>
                        <a
                          className="h-4"
                          href={`tel:` + item.phone_number.replace(/\s/g, '')}
                        >
                          {item.phone_number}
                        </a>
                      </li>
                    ))}
                </ul>
                <ul className="lg:mb-3">
                  {global.email_addresses
                    .filter(item => item.email_address !== null)
                    .map((item, index) => (
                      <li key={index}>
                        <a
                          className="h-4"
                          href={`mailto:` + item.email_address}
                        >
                          {item.email_address}
                        </a>
                      </li>
                    ))}
                </ul>
                <div className="hidden lg:block">
                  <FooterSocialLinks global={global} />
                </div>
              </div>

              {global.address.html && (
                <div
                  className="lg:w-1/4 mb-3 lg:mt-3"
                  dangerouslySetInnerHTML={{ __html: global.address.html }}
                />
              )}

              <div className="lg:hidden">
                <FooterSocialLinks global={global} />
              </div>

              <div className="lg:w-1/4 flex justify-between items-start space-x-gs lg:mt-3">
                <div>
                  {global.footer_menu
                    .filter(item => item.link !== null)
                    .map((item, index) => (
                      <AniLink
                        key={index}
                        fade
                        className="underline"
                        to={item.link.url}
                      >
                        {item.title}
                      </AniLink>
                    ))}
                  <div>&copy; Beverage Brothers | All Rights Reserved</div>
                  <div>
                    Design &amp; art direction by{' '}
                    <a
                      className="underline"
                      href="http://www.doublelux.co/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Double Lux
                    </a>
                  </div>
                </div>
                <div className="lg:block hidden">
                  <FooterArrow />
                </div>
              </div>
            </Fade>
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
